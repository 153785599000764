import classNames from "classnames"
import { useSelector, useDispatch } from "react-redux"
import useScrollLoad from "../hooks/useScrollLoad"
import { fetchAppend, fetchPrepend, viewAlbum, updateAlbum, queueDownloadAll } from "../reducers/rootReducer"

import { ReactComponent as DownloadIcon } from "../images/download.svg"
import { ReactComponent as LikeIcon } from "../images/like.svg"
import AlbumName from "./AlbumName"

export default function AlbumList() {
  const dispatch = useDispatch()
  const albums = useSelector((state) => state.albums)
  let selectionStart = useSelector((state) => state.selectionStart)
  let selectionEnd = useSelector((state) => state.selectionEnd)
  if (selectionStart > selectionEnd) {
    ;[selectionStart, selectionEnd] = [selectionEnd, selectionStart]
  }

  const containerRef = useScrollLoad({
    fetchAppend: () => dispatch(fetchAppend()),
    fetchPrepend: () => dispatch(fetchPrepend()),
  })

  return (
    <div
      ref={containerRef}
      className="flex flex-wrap self-end 2xl:self-center mr-8 2xl:mr-0 py-4 pl-4 bg-gray-200"
      style={{ width: 1008 }}
    >
      {albums.map((album, i) => (
        <AlbumTile album={album} key={album.id} selected={i >= selectionStart && i <= selectionEnd} />
      ))}
    </div>
  )
}

function AlbumTile({ album, selected }) {
  const dispatch = useDispatch()

  const updateStatus = (status) => {
    dispatch(updateAlbum({ id: album.id, status }))
  }

  return (
    <div className={classNames("relative group flex flex-col items-center mr-4 mb-2")} style={{ width: 232 }}>
      <img
        src={album.thumbnail_path}
        title={album.name}
        alt={album.name}
        style={{ width: 232, height: 312 }}
        className="border border-gray-700 rounded overflow-hidden cursor-pointer"
        onClick={() => dispatch(viewAlbum(album.id))}
      />
      <div
        className="absolute border-4 border-transparent group-hover:border-gray-700 rounded pointer-events-none"
        style={{ width: 234, height: 314, left: -2, top: -2 }}
      ></div>
      <div className="absolute bottom-10 w-full flex justify-center">
        <div
          className={classNames(
            "relative mb-1 p-1 flex rounded space-x-3",
            "group-hover:bg-gray-300 group-hover:bg-opacity-75"
          )}
        >
          <button
            className={classNames("icon-btn w-16 h-16 text-red-800 hover:text-red-900", {
              "invisible group-hover:visible": album.status !== "Disliked",
              "bg-gray-300 bg-opacity-75": album.status === "Disliked",
            })}
            onClick={() => updateStatus("Disliked")}
          >
            <LikeIcon className="fill-current w-12 h-12 transform rotate-180" />
          </button>
          <button
            className={classNames("icon-btn w-16 h-16 text-green-700 hover:text-green-800", {
              "invisible group-hover:visible": album.status !== "Liked",
              "bg-gray-300 bg-opacity-75": album.status === "Liked",
            })}
            onClick={() => updateStatus("Liked")}
          >
            <LikeIcon className="fill-current w-12 h-12" />
          </button>
          <button
            className={classNames("icon-btn w-16 h-16", {
              "invisible group-hover:visible": album.status !== "Pending" && album.status !== "Downloaded",
              "bg-gray-300 bg-opacity-75": album.status === "Pending",
              "bg-gray-300 bg-opacity-75 text-green-700 hover:text-green-800": album.status === "Downloaded",
            })}
            // onClick={() => dispatch(queueDownloadAll(album))}
          >
            <DownloadIcon className="fill-current w-12 h-12" />
          </button>
        </div>
      </div>

      <div className="text-14 text-gray-600 h-10 line-clamp-2">
        <AlbumName album={album} onRightClick={(part) => dispatch(appendNameFilter(part.lowerCase))} />
      </div>
    </div>
  )
}

const appendNameFilter = (word) => {
  return (dispatch, getStore) => {
    let name
    if (!getStore().tempFilters.name || getStore().filters.name === getStore().tempFilters.name) {
      name = word
    } else {
      name = getStore().tempFilters.name + " " + word
    }
    dispatch({ type: "appendNameFilter", payload: { tempFilters: { ...getStore().tempFilters, name } } })
  }
}
