import "./App.css"
import HBrowser from "./components/HBrowser"

function App() {
  return (
    <div className="App bg-gray-500 flex items-center justify-center">
      <HBrowser />
    </div>
  )
}

export default App
