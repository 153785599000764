const api = () => {
  function _FETCH(url, body, method) {
    const options = {
      method,
      headers: {
        "Content-Type": "application/json",
      },
    }
    url = new URL(`http://${window.apiRoot}${url}`)

    if (body && method === "GET") {
      Object.keys(body).forEach((key) => body[key] != null && url.searchParams.append(key, body[key]))
    } else if (body) {
      options["body"] = JSON.stringify(body)
    }

    return fetch(url, options).then((response) => {
      return response.json()
    })
  }

  function POST(url, body) {
    return _FETCH(url, body, "POST")
  }

  function PUT(url, body) {
    return _FETCH(url, body, "PUT")
  }

  function DELETE(url, body) {
    return _FETCH(url, body, "DELETE")
  }

  function GET(url, body) {
    return _FETCH(url, body, "GET")
  }

  return {
    POST,
    PUT,
    DELETE,
    GET,
  }
}

export default api
