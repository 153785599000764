import api from "../api"

const PAGE_SIZE = 100

let defaultFilters = window.localStorage.getItem("filters")
if (defaultFilters) {
  defaultFilters = JSON.parse(defaultFilters)
} else {
  defaultFilters = { offset: 0, name: "", status: {} }
}

const initialState = {
  albums: [],
  count: 0,
  sortBy: "sort_order",
  minId: 0,
  maxId: 0,
  overallMinId: 0,
  overallMaxId: 0,
  fetching: false,
  selectionStart: 0,
  selectionEnd: 0,
  filters: defaultFilters,
  tempFilters: defaultFilters,
  viewingAlbum: null,
  files: [],
  fileCount: 0,
  names: {},
  nameWords: { karina: 1, n: 2 },
  lastName: window.localStorage.getItem("lastName") || null,
  studios: {},
  imgSrc: null,
}

export default function rootReducer(state = initialState, action) {
  console.log(action.type)
  if (action.type === "something") {
  } else {
    state = { ...state, ...action.payload }
    if (action.payload?.hasOwnProperty("viewingAlbumId")) {
      state.viewingAlbum = state.albums.find((a) => a.id === state.viewingAlbumId) || null
    } else if (action.payload?.albums && state.viewingAlbumId) {
      state.viewingAlbum = state.albums.find((a) => a.id === state.viewingAlbumId) || null
    }
  }
  return state
}

const setTitle = () => {
  return (dispatch, getStore) => {
    const title = getStore().filters.name || ""
    if (title) {
      document.title = title + " - H-Browser"
    } else {
      document.title = "H-Browser"
    }
  }
}

export const fetchNames = () => {
  return (dispatch, getStore) => {
    return api()
      .GET("/albums/get_names")
      .then(({ names, nameWords }) => dispatch({ type: "fetchNames", payload: { names, nameWords } }))
  }
}

const paramsToSearchString = (state) => {
  const search = new URLSearchParams()
  if (state.filters.name) search.append("name", state.filters.name)
  if (state.filters.searchMode) search.append("searchMode", state.filters.searchMode)
  search.append("gte", state.minId)
  if (state.viewingAlbumId) search.append("viewingAlbumId", state.viewingAlbumId)
  return "?" + search.toString()
}

const searchStringToParams = (string) => {
  const result = {}
  const search = new URLSearchParams(string)
  ;["name", "gte", "searchMode"].forEach((field) => {
    if (search.has(field)) result[field] = search.get(field)
  })
  if (search.has("viewingAlbumId")) result.viewingAlbumId = parseInt(search.get("viewingAlbumId"))
  return result
}

export const setFiltersFromUrl = (searchString) => {
  return (dispatch, getStore) => {
    const params = searchStringToParams(searchString)
    console.log(params)
    const changes = {}
    if (getStore().filters.name !== params.name || getStore().filters.searchMode !== params.searchMode) {
      changes.filters = { ...getStore().filters, name: params.name || "", searchMode: params.searchMode }
      changes.tempFilters = changes.filters
    }
    if (getStore().minId !== params.gte) {
      changes.gte = params.gte
    }
    if (getStore().viewingAlbumId !== params.viewingAlbumId) {
      changes.viewingAlbumId = params.viewingAlbumId
    }
    console.log(changes)
    if (Object.entries(changes).length > 0) {
      dispatch({ type: "setFiltersFromUrl", payload: changes })
      dispatch(fetchNew(params.gte))
      if (changes.viewingAlbumId) dispatch(viewAlbum(changes.viewingAlbumId))
    }
  }
}

const startFetch = () => {
  return (dispatch, getStore) => {
    if (getStore().fetching) return false
    dispatch({ type: "startFetch", payload: { fetching: true } })
    return true
  }
}

export const fetchNew = (gte) => {
  return (dispatch, getStore) => {
    if (!dispatch(startFetch())) return

    const filters = { ...getStore().tempFilters }
    if (gte) filters.gte = gte

    return api()
      .POST("/albums/get_albums", filters)
      .then(({ albums, count, max_id, min_id }) => {
        const min = albums.length ? albums[0][getStore().sortBy] : 0
        const max = albums.length ? albums[albums.length - 1][getStore().sortBy] : 0
        window.scrollTo(0, 0)
        dispatch({
          type: "fetchNew",
          payload: {
            albums,
            count,
            minId: min,
            maxId: max,
            overallMinId: min_id,
            overallMaxId: max_id,
            fetching: false,
            selectionStart: 0,
            selectionEnd: 0,
            filters: getStore().tempFilters,
          },
        })
        window.localStorage.setItem("filters", JSON.stringify(getStore().filters))
        dispatch(setTitle())
        const search = paramsToSearchString(getStore())
        if (window.location.search !== search) {
          window.history.pushState({}, "", search)
        }
        window.scrollTo(0, 1)
        if (min_id < min) dispatch(fetchPrepend())
      })
      .catch(() => dispatch({ type: "errorFetch", payload: { fetching: false } }))
  }
}

export const fetchAppend = () => {
  return (dispatch, getStore) => {
    const { maxId, overallMaxId, filters } = getStore()
    if (maxId < overallMaxId) {
      if (!dispatch(startFetch())) return

      return api()
        .POST("/albums/get_albums", { ...filters, gt: maxId })
        .then(({ albums, count, min_id, max_id }) => {
          albums = getStore().albums.concat(albums)
          const max = albums.length ? albums[albums.length - 1][getStore().sortBy] : 0
          dispatch({
            type: "fetchAppend",
            payload: { albums, count, fetching: false, maxId: max, overallMaxId: max_id, overallMinId: min_id },
          })
        })
    }
  }
}

export const fetchPrepend = () => {
  return (dispatch, getStore) => {
    const { minId, overallMinId, filters } = getStore()
    if (minId > overallMinId) {
      if (!dispatch(startFetch())) return

      return api()
        .POST("/albums/get_albums", { ...filters, lt: minId })
        .then(({ albums, count, min_id, max_id }) => {
          const additionalCount = albums.length
          albums = albums.concat(getStore().albums)
          const min = albums.length ? albums[0][getStore().sortBy] : 0
          const { selectionStart, selectionEnd } = getStore()
          dispatch({
            type: "fetchPrepend",
            payload: {
              albums,
              count,
              minId: min,
              overallMinId: min_id,
              overallMaxId: max_id,
              fetching: false,
              selectionStart: selectionStart + additionalCount,
              selectionEnd: selectionEnd + additionalCount,
            },
          })
        })
    }
  }
}

export const setFilters = (newFilters) => {
  return (dispatch, getStore) => {
    dispatch({ type: "setFilters", payload: { tempFilters: { ...getStore().tempFilters, ...newFilters } } })
  }
}

export const resetFilters = () => {
  return (dispatch, getStore) => {
    dispatch({ type: "resetFilters", payload: { tempFilters: getStore().filters } })
  }
}

export const viewAlbum = (viewingAlbumId) => {
  return (dispatch, getStore) => {
    dispatch({ type: "viewAlbum", payload: { viewingAlbumId, files: [], fileCount: 0 } })
    const album = getStore().viewingAlbum
    if (album) {
      dispatch(setTitle())
      const search = paramsToSearchString({ ...getStore(), minId: album.sort_order })
      window.history.replaceState({}, "", search)
    }
  }
}

export const stopViewingAlbum = () => {
  return (dispatch, getStore) => {
    const album = getStore().viewingAlbum
    dispatch({
      type: "stopViewingAlbum",
      payload: {
        viewingAlbumId: null,
        files: [],
        fileCount: 0,
      },
    })
    dispatch(setTitle())
    const search = paramsToSearchString({ ...getStore(), minId: album.sort_order })
    window.history.replaceState({}, "", search)
  }
}

export const queueDownloadAll = (album, count) => {
  return (dispatch, getStore) => {
    if (album.status === "Downloaded") return
    return api()
      .POST("/files/queue_download_all", { album_id: album.id, count })
      .then((album) => dispatch(changeAlbum(album)))
  }
}

export const queueDownloads = () => {
  return (dispatch, getStore) => {
    const files = getStore().files
    const album = getStore().viewingAlbum
    if (!files.length) return

    const data = files.map((file) => (file.status === null ? "Canceled" : file.status))
    api()
      .POST("/files/queue_downloads", { data, album_id: album.id })
      .then((album) => dispatch(changeAlbum(album)))
  }
}

export const updateAlbum = (album) => {
  return (dispatch, getStore) => {
    return api()
      .PUT(`/albums/update_album/${album.id}`, album)
      .then(({ album, names, nameWords }) => {
        let newNames, newNameWords
        if (names.added.length || names.removed.length) {
          newNames = { ...getStore().names }
          names.added.forEach((name) => (newNames[name.name] = name.id))
          names.removed.forEach((name) => delete newNames[name.name])
        }
        if (nameWords.added.length || nameWords.removed.length) {
          newNameWords = { ...getStore().nameWords }
          nameWords.added.forEach((name) => (newNameWords[name.name] = name.id))
          nameWords.removed.forEach((name) => delete newNameWords[name.name])
        }
        if (newNames || newNameWords) {
          dispatch({
            type: "updateNames",
            payload: { names: newNames || getStore().names, nameWords: newNameWords || getStore().nameWords },
          })
        }
        dispatch(changeAlbum(album))
      })
  }
}

export const changeAlbum = (album) => {
  return (dispatch, getStore) => {
    const index = getStore().albums.findIndex((a) => a.id === album.id)
    if (index >= 0) {
      const albums = [...getStore().albums]
      albums[index] = album
      return dispatch({ type: "changeAlbum", payload: { albums } })
    }
  }
}
