import { debounce } from "@material-ui/core"
import classNames from "classnames"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateAlbum, changeAlbum } from "../reducers/rootReducer"

const splitRegex = /([ \-_]+)/

export default function AlbumName({ album, onRightClick }) {
  const dispatch = useDispatch()
  const nameWords = useSelector((state) => (album.artist_name ? null : state.nameWords))
  const names = useSelector((state) => (album.artist_name ? null : state.names))
  const parts = processName({ name: album.name, artistName: album.artist_name, names, nameWords })

  const toggleArtistName = (part) => {
    const newParts = [...parts]
    const index = newParts.indexOf(part)
    newParts[index] = { ...part, isArtistName: !part.isArtistName }
    const artistName = buildArtistName(newParts)
    dispatch(changeAlbum({ ...album, artist_name: artistName || null }))
    updateArtistName(dispatch, album, artistName)
  }

  const updateArtistName = useCallback(
    debounce((dispatch, album, artistName) => {
      dispatch(updateAlbum({ id: album.id, artist_name: artistName || null }))
    }, 1500),
    []
  )

  return parts.map((part, index) => {
    if (part.isWord) {
      return (
        <span
          key={index}
          className={classNames("cursor-pointer hover:underline", {
            "text-black underline": part.isArtistName,
          })}
          onMouseDown={(e) => {
            if (e.buttons === 1) {
              toggleArtistName(part)
            } else if (e.buttons === 2) {
              onRightClick && onRightClick(part)
            }
          }}
          onContextMenu={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          {part.text}
        </span>
      )
    } else {
      return <span key={index}>{part.text}</span>
    }
  })
}

export function processName({ name, artistName, names, nameWords }) {
  let parts = name
    .split(splitRegex)
    .filter((x) => x)
    .map((part) => {
      return {
        text: part,
        lowerCase: part.toLowerCase(),
        isWord: !splitRegex.test(part),
        isArtistName: false,
      }
    })

  if (artistName) {
    const artistNameParts = artistName.toLowerCase().split(" ")
    let i = 0
    // go one past the end in case we are partway through a name at the end
    for (let j = 0; j <= parts.length; j++) {
      const part = parts[j]
      if (!part?.isWord) continue
      if (part?.lowerCase === artistNameParts[i]) {
        part.isArtistName = true
        i++
        if (i === artistNameParts.length) break
      } else if (i > 0) {
        parts.forEach((part) => (part.isArtistName = false))
        i = 0
      }
    }
  } else {
    let i = 0
    // go one past the end in case we are partway through a name at the end
    for (let j = 0; j <= parts.length; j++) {
      const part = parts[j]
      if (!part?.isWord) continue
      if (part && nameWords[part.lowerCase]) {
        part.isArtistName = true
        i++
        if (i >= 2) break
      } else if (i > 0) {
        // if one word is a name, just use that
        const partialName = parts.find((part) => part.isArtistName).lowerCase
        if (names[partialName]) {
          break
        } else {
          parts.forEach((part) => (part.isArtistName = false))
          i = 0
        }
      }
    }
  }

  return parts
}

export function buildArtistName(parts) {
  return parts
    .filter((x) => x.isArtistName)
    .map((x) => x.text)
    .join(" ")
}
