import ReactDOM from "react-dom"
import { useSelector, useDispatch } from "react-redux"

export default function ImageViewer() {
  const dispatch = useDispatch()
  const imgSrc = useSelector((state) => state.imgSrc)

  if (!imgSrc) return null

  return ReactDOM.createPortal(
    <div className="fixed h-full w-full top-0 left-0 flex justify-center bg-black bg-opacity-50 z-20 overflow-y-auto">
      <img
        src={imgSrc}
        className="max-w-full cursor-pointer"
        onClick={() => dispatch({ type: "closeImage", payload: { imgSrc: null } })}
      />
    </div>,
    document.body
  )
}
