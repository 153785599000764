import { debounce } from "@material-ui/core"
import classNames from "classnames"
import ReactDOM from "react-dom"
import { useSelector, useDispatch } from "react-redux"
import api from "../api"
import { queueDownloadAll, updateAlbum, queueDownloads, stopViewingAlbum } from "../reducers/rootReducer"

import { ReactComponent as DownloadIcon } from "../images/download.svg"
import { ReactComponent as DeleteIcon } from "../images/delete.svg"
import { ReactComponent as LikeIcon } from "../images/like.svg"
import { ReactComponent as CheckIcon } from "../images/check.svg"
import { useEffect, useState } from "react"
import AlbumName, { buildArtistName, processName } from "./AlbumName"

export default function FileList() {
  const dispatch = useDispatch()
  const album = useSelector((state) => state.viewingAlbum)
  const files = useSelector((state) => state.files)

  useEffect(() => {
    document.body.style.overflow = album ? "hidden" : "unset"
  }, [album])

  useEffect(() => {
    if (album) {
      dispatch(getFiles({ album }))
    }
  }, [album?.id])

  if (!album) return null

  return ReactDOM.createPortal(
    <div className="fixed h-full w-full top-0 left-0 flex justify-end 2xl:justify-center bg-black bg-opacity-50 z-10">
      <div className="relative h-full mr-8 2xl:mr-0">
        <AlbumControls />
        <div className="h-full bg-gray-200 border-x-2 border-black overflow-y-scroll">
          <div className="flex flex-wrap py-4 pl-4" style={{ width: 1008 }} onMouseDown={(e) => e.preventDefault()}>
            {files.map((file) => (
              <FileTile key={file.path} file={file} album={album} />
            ))}
          </div>
        </div>
      </div>
    </div>,
    document.body
  )
}

function FileTile({ file, album }) {
  const dispatch = useDispatch()
  const status = file.status

  const handleDownload = (e) => {
    if (!e.ctrlKey && !e.shiftKey) {
      return
    }
    if (e.buttons === 1) {
      dispatch(setDownloadStatus(file, "", { gte: e.ctrlKey, lte: e.shiftKey }))
      debouncedDownload(dispatch)
    } else if (e.buttons === 2) {
      dispatch(setDownloadStatus(file, "Canceled", { gte: e.ctrlKey, lte: e.shiftKey }))
      debouncedDownload(dispatch)
    }
  }
  return (
    <a
      target="_blank"
      className={classNames("relative flex flex-col items-center mr-4 mb-2")}
      style={{ width: 232 }}
      href={file.path}
      onClick={(e) => {
        if (e.ctrlKey || e.shiftKey) {
          e.preventDefault()
        }
      }}
    >
      <img
        src={file.thumbnail_path}
        style={{ width: 232, height: 312 }}
        className=" border border-gray-700 rounded overflow-hidden"
        onMouseOver={handleDownload}
        onMouseDown={handleDownload}
        onContextMenu={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      />
      {status !== null && status !== "Canceled" && (
        <DownloadIcon
          className={classNames("fill-current absolute bottom-0 right-0 mr-4 mb-2 h-24 w-24 pointer-events-none z-10", {
            "text-black opacity-75": status === "",
            "text-green-700": status === "Done",
            "text-red-700": status === "Failed",
          })}
        />
      )}
    </a>
  )
}

function AlbumControls() {
  const dispatch = useDispatch()
  const album = useSelector((state) => state.viewingAlbum)
  const files = useSelector((state) => state.files)

  const nameWords = useSelector((state) => (album.artist_name ? null : state.nameWords))
  const names = useSelector((state) => (album.artist_name ? null : state.names))
  const parts = processName({ name: album.name, artistName: album.artist_name, names, nameWords })
  const guessedName = buildArtistName(parts)

  const [artistName, setArtistName] = useState(album.artist_name)

  const lastName = useSelector((state) => state.lastName)

  const updateStatus = async (status) => {
    await dispatch(updateAlbum({ id: album.id, status }))
    dispatch(stopViewingAlbum())
  }

  return (
    <div
      className={classNames(
        "z-20 absolute bottom-4 flex flex-col p-4",
        "bg-gray-400 border border-gray-700 rounded shadow-lg"
      )}
      style={{ left: -300 }}
    >
      <div className="flex w-64 flex-wrap text-14 text-gray-600 line-clamp-4 -m-2 mb-4 py-2 px-3 rounded bg-gray-300">
        <AlbumName
          album={album}
          onRightClick={(part) => setArtistName(artistName ? `${artistName} ${part.text}` : part.text)}
        />
      </div>
      <div className="text-16 mb-4">{files.length} Images</div>
      <div className="flex justify-between w-full">
        <div className="flex flex-col">
          <label htmlFor="artistName" className="text-14 font-medium mb-2 text-left">
            Artist Name
          </label>
          <div className="relative">
            <input
              id="artistName"
              name="artistName"
              className="text-input text-14 w-44 text-black"
              value={artistName === null ? guessedName : artistName}
              onChange={(e) => setArtistName(e.target.value)}
            />
            <button
              className={classNames("icon-btn absolute right-1 top-1 rounded w-6 h-6 hover:text-gray-900", {
                "text-gray-400": album.artist_name === null || album.artist_name !== artistName,
                "text-green-500": album.artist_name !== null && album.artist_name === artistName,
              })}
              onClick={async () => {
                const name = artistName === null ? guessedName : artistName
                await dispatch(updateAlbum({ id: album.id, artist_name: name }))
                dispatch({ type: "setLastName", payload: { lastName: name } })
                window.localStorage.setItem("lastName", name)
                setArtistName(name)
              }}
            >
              <CheckIcon className="fill-current w-4 h-4" />
            </button>
          </div>
          {lastName && (
            <button
              className="flex text-blue-700 hover:text-blue-800 text-14 font-medium mt-4 ml-2"
              onClick={async () => {
                await dispatch(updateAlbum({ id: album.id, artist_name: lastName }))
                setArtistName(lastName)
              }}
            >
              {lastName}
            </button>
          )}
        </div>
        <div className="flex flex-col">
          <button
            className="icon-btn w-16 h-16 text-gray-700 hover:text-gray-900"
            onClick={() => dispatch(stopViewingAlbum())}
          >
            <CheckIcon className="fill-current w-12 h-12" />
          </button>
          <button
            className={classNames("icon-btn w-16 h-16", {
              "bg-gray-900 bg-opacity-25": album.status === "Pending",
              "bg-gray-900 bg-opacity-25 text-green-800": album.status === "Downloaded",
            })}
            onClick={async () => {
              await dispatch(queueDownloadAll(album, files.length))
              dispatch(stopViewingAlbum())
            }}
          >
            <DownloadIcon className="fill-current w-12 h-12" />
          </button>
          <button
            className={classNames("icon-btn w-16 h-16 text-green-700 hover:text-green-800", {
              "bg-gray-900 bg-opacity-25": album.status === "Liked",
            })}
            onClick={() => updateStatus("Liked")}
          >
            <LikeIcon className="fill-current w-12 h-12" />
          </button>
          <button
            className={classNames("icon-btn w-16 h-16 text-red-800 hover:text-red-900", {
              "bg-gray-900 bg-opacity-25": album.status === "Disliked",
            })}
            onClick={() => updateStatus("Disliked")}
          >
            <LikeIcon className="fill-current w-12 h-12 transform rotate-180" />
          </button>
          <button
            className={classNames("icon-btn w-16 h-16 hover:text-red-900", {
              "bg-gray-900 bg-opacity-25": album.status === "Deleted",
            })}
            onClick={() => updateStatus("Deleted")}
          >
            <DeleteIcon className="fill-current w-12 h-12" />
          </button>
        </div>
      </div>
    </div>
  )
}

const setDownloadStatus = (file, status, { gte, lte } = {}) => {
  return (dispatch, getStore) => {
    if (file.status === "Done") return

    const files = [...getStore().files]
    const index = files.indexOf(file)
    if (index >= 0) {
      if (gte) {
        for (let i = index; i < files.length; i++) {
          files[i] = { ...files[i], status }
        }
      } else if (lte) {
        for (let i = 0; i <= index; i++) {
          files[i] = { ...files[i], status }
        }
      } else {
        files[index] = { ...files[index], status }
      }
      dispatch({ type: "setDownloadStatus", payload: { files } })
    }
  }
}

const dispatchQueueDownloads = (dispatch) => dispatch(queueDownloads())

const debouncedDownload = debounce(dispatchQueueDownloads, 1000)

const getFiles = ({ album, offset = 0 }) => {
  return (dispatch, getStore) => {
    return api()
      .GET("/files/get_files", { album_id: album.id, offset })
      .then(({ files, count, offset }) => {
        if (getStore().viewingAlbumId === album.id) {
          dispatch({
            type: "getFiles",
            payload: {
              files: getStore().files.concat(files),
              fileCount: count,
            },
          })
          if (getStore().files.length < getStore().fileCount) {
            dispatch(getFiles({ album, offset: getStore().files.length }))
          }
        }
      })
      .catch(() => {
        dispatch({ type: "viewAlbumError", payload: { viewingAlbumId: null, files: [], fileCount: 0 } })
      })
  }
}

const openImage = (file) => {
  return (dispatch, getStore) => {
    return api()
      .GET("/files/get_img_src", file)
      .then((url) => dispatch({ type: "openImage", payload: { imgSrc: url } }))
  }
}
