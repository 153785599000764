import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchMore } from "../reducers/rootReducer"

export default function useScrollLoad({ fetchAppend, fetchPrepend }) {
  const containerRef = useRef()
  const fetching = useSelector((state) => state.fetching)

  useEffect(() => {
    if (!fetching) {
      const handler = (e) => {
        const windowHeight = window.innerHeight
        const containerHeight = containerRef.current.offsetHeight
        const scrollY = window.scrollY
        const maxScroll = containerHeight - windowHeight

        if (maxScroll > 0 && maxScroll - scrollY < 2000) {
          fetchAppend()
        }
        if (scrollY < 2000) {
          fetchPrepend()
        }
      }
      document.addEventListener("scroll", handler)
      return () => {
        document.removeEventListener("scroll", handler)
      }
    }
  }, [fetchAppend, fetchPrepend, fetching])

  return containerRef
}
