import classNames from "classnames"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setFilters, resetFilters, fetchNew } from "../reducers/rootReducer"

export default function Filters() {
  const dispatch = useDispatch()
  const filters = useSelector((state) => state.filters)
  const tempFilters = useSelector((state) => state.tempFilters)
  const { status, name, offset, searchMode } = tempFilters
  const toggleStatus = (key) => dispatch(setFilters({ status: { ...status, [key]: status[key] === false } }))
  const setName = (s) => dispatch(setFilters({ name: s }))
  const setOffset = (x) => dispatch(setFilters({ offset: parseInt(x) || 0 }))
  const count = useSelector((state) => state.count)

  useEffect(() => {
    if (offset === filters.offset && name !== filters.name) {
      setOffset(0)
    }
  }, [filters.offset, filters.name, name])

  return (
    <div className={classNames("fixed ml-2 mt-2 bg-gray-400 border border-gray-700 rounded shadow-lg")}>
      <form
        id="filters"
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(fetchNew())
        }}
      >
        <div className="flex p-4">
          <div className="flex flex-col w-28">
            <label className="text-14 font-medium mb-2 text-left">Status</label>
            {["", "Pending", "Downloaded", "Liked", "Disliked", "Deleted"].map((s) => (
              <label className="flex items-center" key={s}>
                <input type="checkbox" checked={status[s] !== false} onChange={() => toggleStatus(s)} />
                <span className="ml-1 text-12 w-3 text-right">{s || "None"}</span>
              </label>
            ))}
          </div>
          <div className="flex flex-col">
            <label htmlFor="albumName" className="text-14 font-medium mb-2 text-left">
              Album Name
            </label>
            <div className="relative">
              <input
                id="albumName"
                name="albumName"
                className="text-input text-14 w-48 text-black"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onFocus={(e) => e.target.select()}
              />
              <button
                type="button"
                className="absolute top-1.5 right-4 focus:outline-none"
                onClick={() => dispatch(setFilters({ searchMode: searchMode === "and" ? undefined : "and" }))}
              >
                {searchMode === "and" ? "&" : "~"}
              </button>
            </div>
            <label htmlFor="offset" className="text-14 font-medium my-2 text-left">
              Offset
            </label>
            <div className="relative">
              <input
                id="offset"
                name="offset"
                className="text-input text-14 w-48 text-black"
                value={offset}
                onChange={(e) => setOffset(e.target.value)}
              />
              <button
                type="button"
                className="absolute top-1.5 right-4 focus:outline-none"
                onClick={() => setOffset(Math.floor(Math.random() * count))}
              >
                ?
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-between px-4 py-2 border-t border-gray-500">
          <div>{count} results</div>
          <div className="flex">
            <button className="btn mr-2" type="button" onClick={() => dispatch(resetFilters())}>
              Cancel
            </button>
            <button className="btn" type="submit" id="submitFilters">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
