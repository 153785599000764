import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import classNames from "classnames"
import { fetchNames, fetchNew, setFiltersFromUrl } from "../reducers/rootReducer"
import AlbumList from "./AlbumList"
import Filters from "./Filters"
import FileList from "./FileList"
import { useLocation } from "react-router-dom"
import ImageViewer from "./ImageViewer"

export default function HBrowser({}) {
  const dispatch = useDispatch()
  const search = useLocation().search
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    dispatch(setFiltersFromUrl(search))
  }, [search])

  useEffect(() => {
    dispatch(fetchNames())
      // .then(() => dispatch(fetchNew()))
      .then(() => setLoaded(true))
  }, [])

  return (
    <div className={classNames("flex flex-col w-full", {})}>
      {loaded && (
        <>
          <Filters />
          <AlbumList />
          <FileList />
          <ImageViewer />
        </>
      )}
    </div>
  )
}
